import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faUserAstronaut } from '@fortawesome/free-solid-svg-icons';
import useHome from './useHome';

export default function Homepage() {
  const { isSignedIn, onDashboardClick, onSiginClick } = useHome();
  return (
    <div className="homepage-wrapper">
      <header>
        <div className="container">
          <div className="header-inner d-flex align-items-center">
            <div className="logo me-3">
              <a href="/">
                <FontAwesomeIcon icon={faUserAstronaut} />
              </a>
            </div>
            <div className="menu-wrapper ms-auto">
              <ul className="menu d-flex align-items-center">
                {isSignedIn ? (
                  <li>
                    <span onClick={() => onDashboardClick()} className="btn btn-primary py-2">
                      Dashboard
                    </span>
                  </li>
                ) : (
                  <li>
                    <span onClick={() => onSiginClick()} className="btn btn-primary py-2">
                      Login
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </header>

      <section className="py-5 problems-section">
        <div className="container">
          <h2 className="mb-4 mb-md-5 text-center">Problems a candidate faces in Job Market</h2>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-6 mb-3  order-2 order-md-1">
                  <div className="problem-box h-100">
                    <h3 className="mb-3">Where does it come from?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum finibus lorem,</p>
                  </div>
                </div>
                <div className="col-md-6 mb-3  order-3 order-md-2">
                  <div className="problem-box h-100">
                    <h3 className="mb-3">Where does it come from?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum finibus lorem,</p>
                  </div>
                </div>

                <div className="col-md-6 mb-3 order-4 order-md-3">
                  <div className="problem-box h-100">
                    <h3 className="mb-3">Where does it come from?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum finibus lorem,</p>
                  </div>
                </div>

                <div className="col-md-6 mb-3 order-1 order-md-4">
                  <div className="problem-box h-100 d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 problems-section">
        <div className="container">
          <h2 className="mb-4 mb-md-5 text-center">Problems that you are facing</h2>
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="problem-box h-100 d-flex align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="problem-box h-100">
                    <h3 className="mb-3">Where does it come from?</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum finibus lorem, a
                      venenatis erat laoreet eu. Aenean vel nibh auctor, ultricies nibh sed, malesuada tortor. In vel
                      tortor vel dolor fringilla rhoncus. Maecenas auctor risus mi, nec tincidunt elit placerat
                      fermentum. Aenean nisl nunc, sagittis non ex ut, congue ultricies odio. Etiam sed risus sed lacus
                      volutpat feugiat a sed nibh. Vivamus malesuada diam vitae diam convallis, non convallis magna
                      iaculis.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="problem-box h-100">
                    <h3 className="mb-3">Where does it come from?</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum finibus lorem, a
                      venenatis erat laoreet eu. Aenean vel nibh auctor, ultricies nibh sed, malesuada tortor. In vel
                      tortor vel dolor fringilla rhoncus. Maecenas auctor risus mi, nec tincidunt elit placerat
                      fermentum. Aenean nisl nunc, sagittis non ex ut, congue ultricies odio. Etiam sed risus sed lacus
                      volutpat feugiat a sed nibh. Vivamus malesuada diam vitae diam convallis, non convallis magna
                      iaculis.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="problem-box h-100">
                    <h3 className="mb-3">Where does it come from?</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam condimentum finibus lorem, a
                      venenatis erat laoreet eu. Aenean vel nibh auctor, ultricies nibh sed, malesuada tortor. In vel
                      tortor vel dolor fringilla rhoncus. Maecenas auctor risus mi, nec tincidunt elit placerat
                      fermentum. Aenean nisl nunc, sagittis non ex ut, congue ultricies odio. Etiam sed risus sed lacus
                      volutpat feugiat a sed nibh. Vivamus malesuada diam vitae diam convallis, non convallis magna
                      iaculis.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="grow-business pb-5 ">
        <div className="container">
          <div className="row align-items-center justify-content-center mt-5 ">
            <div className="col-md-7 text-center">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="merchant-feature-box h-100  bg-light rounded p-4">
                    <div className="img-box mb-3">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div className="content">
                      <h4>Lorem Ipsum</h4>
                      {/* <p>Vendors can easily update their menus and add new items to their offerings using the contactless ordering system. This allows them to tailor their menus to their customers' preferences and adapt to changing trends and tastes.

                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="merchant-feature-box h-100  bg-light rounded p-4">
                    <div className="img-box mb-3">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div className="content">
                      <h4>Lorem Ipsum</h4>
                      {/* <p>By using a contactless ordering system, vendors can collect valuable data on their customers' ordering habits, preferences, and feedback. This data can be used to make informed decisions about menu changes, promotions, and other business strategies.

                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6  mb-4">
                  <div className="merchant-feature-box h-100  bg-light rounded p-4">
                    <div className="img-box mb-3">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div className="content">
                      <h4>Lorem Ipsum</h4>
                      {/* <p>Contactless ordering systems can be used to promote specials, discounts, and other marketing initiatives directly to customers' devices. This can help vendors increase their customer base and encourage repeat business.

                      </p> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6  mb-4">
                  <div className="merchant-feature-box h-100  bg-light rounded p-4">
                    <div className="img-box mb-3">
                      <FontAwesomeIcon icon={faPhone} />
                    </div>
                    <div className="content">
                      <h4>Lorem ipsum</h4>
                      {/* <p>Vendors can implement loyalty programs using contactless ordering, providing incentives for customers to return and purchase more items. This can help vendors build a loyal customer base and increase sales over time.

                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="advantages-section py-5">
        <div className="container">
          <h2 className="text-center mb-4 mb-md-5">Advantages of using AI Clues for Candidate</h2>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="advantage-box bg-light rounded text-center p-4 h-100 mb-4">
                <div className="img-box mb-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h4 className="mb-3">Increased Efficiency</h4>
                <p>
                  Nam id neque vitae lectus commodo tempor ut in elit. Phasellus et sollicitudin urna, vitae molestie
                  lectus. Aenean porta pellentesque condimentum. Duis ultrices erat ut quam blandit, et dignissim nisl
                  mollis. Praesent efficitur dolor non eros accumsan, eget efficitur lectus interdum. Vestibulum quis mi
                  viverra, feugiat lorem eget, blandit quam.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="advantage-box bg-light rounded text-center p-4 h-100 mb-4">
                <div className="img-box mb-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h4 className="mb-3">Reduced Costs</h4>
                <p>
                  Nam id neque vitae lectus commodo tempor ut in elit. Phasellus et sollicitudin urna, vitae molestie
                  lectus. Aenean porta pellentesque condimentum. Duis ultrices erat ut quam blandit, et dignissim nisl
                  mollis. Praesent efficitur dolor non eros accumsan, eget efficitur lectus interdum. Vestibulum quis mi
                  viverra, feugiat lorem eget, blandit quam.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="advantage-box bg-light rounded text-center p-4 h-100 mb-4">
                <div className="img-box mb-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h4 className="mb-3">Job Handler</h4>
                <p>
                  {' '}
                  Nam id neque vitae lectus commodo tempor ut in elit. Phasellus et sollicitudin urna, vitae molestie
                  lectus. Aenean porta pellentesque condimentum. Duis ultrices erat ut quam blandit, et dignissim nisl
                  mollis. Praesent efficitur dolor non eros accumsan, eget efficitur lectus interdum. Vestibulum quis mi
                  viverra, feugiat lorem eget, blandit quam.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="advantage-box bg-light rounded text-center p-4 h-100 mb-4">
                <div className="img-box mb-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h4 className="mb-3">Tailored Resume Maker</h4>
                <p>
                  Nam id neque vitae lectus commodo tempor ut in elit. Phasellus et sollicitudin urna, vitae molestie
                  lectus. Aenean porta pellentesque condimentum. Duis ultrices erat ut quam blandit, et dignissim nisl
                  mollis. Praesent efficitur dolor non eros accumsan, eget efficitur lectus interdum. Vestibulum quis mi
                  viverra, feugiat lorem eget, blandit quam.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="advantage-box bg-light rounded text-center p-4 h-100 mb-4">
                <div className="img-box mb-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h4 className="mb-3">Auto fill based on User Profile</h4>
                <p>
                  {' '}
                  Nam id neque vitae lectus commodo tempor ut in elit. Phasellus et sollicitudin urna, vitae molestie
                  lectus. Aenean porta pellentesque condimentum. Duis ultrices erat ut quam blandit, et dignissim nisl
                  mollis. Praesent efficitur dolor non eros accumsan, eget efficitur lectus interdum. Vestibulum quis mi
                  viverra, feugiat lorem eget, blandit quam.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4">
              <div className="advantage-box bg-light rounded text-center p-4 h-100 mb-4">
                <div className="img-box mb-3">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <h4 className="mb-3">Auto Apply using AI</h4>
                <p>
                  {' '}
                  Nam id neque vitae lectus commodo tempor ut in elit. Phasellus et sollicitudin urna, vitae molestie
                  lectus. Aenean porta pellentesque condimentum. Duis ultrices erat ut quam blandit, et dignissim nisl
                  mollis. Praesent efficitur dolor non eros accumsan, eget efficitur lectus interdum. Vestibulum quis mi
                  viverra, feugiat lorem eget, blandit quam.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        <div className="copyright-text py-3 border-top border-white">
          <p className="text-center mb-0">© Copyright 2023, AI Clues. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}
