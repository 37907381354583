/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  fetch_user_mock_interview_action,
  fetch_user_mock_interview_qestion_feedback_action,
  fetch_user_mock_interview_questions_action,
  fetch_user_mux_upload_asset_ids_action,
  fetch_user_mux_upload_url_action,
} from '../../../_redux/action';
import * as UpChunk from '@mux/upchunk';

function useMockInterview(props) {
  const stopButtonRef = useRef(null);
  const videoElement = useRef(null);
  const [loading, setLoading] = useState(false);
  const [userMockInterview, setUserMockInterview] = useState(null);
  const [questionsLoading, setQuestionsLoading] = useState(null);
  const [generatedUserQuestions, setGeneratedUserQuestions] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [feedbackLoading, setFeedbackLoading] = useState(false);
  const [startInterview, setStartInterview] = useState(null);
  const [questionFeedback, setQuestionFeedback] = useState(null);

  const dispatch = useDispatch();
  const params = useParams();

  const { mockInterviewData } = useSelector((state) => state.fetch_user_mock_interview_reducer);

  useEffect(() => {
    const { id } = params;
    const callApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_mock_interview_action(id));
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    if (mockInterviewData?.savedAiMockInterview) {
      setUserMockInterview(mockInterviewData?.savedAiMockInterview);
    }
    if (mockInterviewData?.savedAiInterviewQuestions) {
      setGeneratedUserQuestions(mockInterviewData?.savedAiInterviewQuestions);
    }
  }, [mockInterviewData]);

  useEffect(() => {
    if (currentPage !== null) {
      startRecording();
    }
  }, [currentPage]);

  const onStartClick = async (interview_type) => {
    setQuestionsLoading(true);
    if (Array.isArray(generatedUserQuestions) && generatedUserQuestions.length) {
      setCurrentPage(0);
      setStartInterview(true);
    } else {
      let res = await dispatch(
        fetch_user_mock_interview_questions_action(userMockInterview?._id, {
          interview_type,
        })
      );

      if (res) {
        setCurrentPage(0);
        setStartInterview(true);
        setGeneratedUserQuestions(res);
      }
    }
    setQuestionsLoading(false);
  };

  function startRecording() {
    const constraints = {
      audio: {
        autoGainControl: false,
        echoCancellation: false,
        noiseSuppression: false,
      },
      video: true,
      echoCancellation: true,
    };
    navigator.mediaDevices.getUserMedia(constraints).then(function (stream) {
      const options = {
        mimeType: 'video/webm',
      };
      const recordedChunks = [];
      const mediaRecorder = new MediaRecorder(stream, options);

      if (videoElement?.current) videoElement.current.srcObject = stream;

      mediaRecorder.addEventListener('dataavailable', function (e) {
        if (e.data.size > 0) recordedChunks.push(e.data);
      });

      mediaRecorder.addEventListener('stop', function () {
        stream.getTracks().forEach((track) => track.stop());
        videoObject(URL.createObjectURL(new Blob(recordedChunks)));
      });

      if (stopButtonRef && stopButtonRef.current) {
        stopButtonRef?.current?.addEventListener('click', function onStopClick() {
          mediaRecorder.stop();
          this.removeEventListener('click', onStopClick);
          videoElement.current.srcObject = null;
        });
      }

      mediaRecorder.start();
    });
  }

  const videoObject = async (url) => {
    const imageResponse = await fetch(url);
    let data = await imageResponse.blob();
    let uploadFile = new File([data], `${generatedUserQuestions[currentPage]._id}.webm`, {
      type: 'video/webm',
    });

    setSaveLoading(true);

    const urlRes = await dispatch(fetch_user_mux_upload_url_action());

    if (urlRes) {
      const upload = UpChunk.createUpload({
        endpoint: urlRes.url,
        file: uploadFile,
        chunkSize: 30720,
      });

      upload.on('error', (err) => {
        console.error('💥 🙀', err.detail);
      });

      upload.on('progress', (progress) => {
        console.log(`So far we've uploaded ${progress.detail}% of this file.`);
      });

      upload.on('success', async () => {
        console.log("Wrap it up, we're done here. 👋");
        let assetsRes = await dispatch(
          fetch_user_mux_upload_asset_ids_action({
            question: generatedUserQuestions[currentPage]._id,
            uploadId: urlRes.id,
          })
        );
        if (assetsRes) {
          onCurrentPageChange();
        }
        setSaveLoading(false);
      });
    }
  };

  const onCurrentPageChange = () => {
    if (currentPage + 1 < generatedUserQuestions.length) {
      setCurrentPage((v) => v + 1);
      setQuestionFeedback(null);
    }
  };

  const onGenerateQuestionFeedback = async () => {
    setFeedbackLoading(true);
    let res = await dispatch(
      fetch_user_mock_interview_qestion_feedback_action(generatedUserQuestions[currentPage]?._id)
    );
    setQuestionFeedback(res);
    setFeedbackLoading(false);
  };

  return {
    loading,
    userMockInterview,
    questionsLoading,
    startInterview,
    feedbackLoading,
    questionFeedback,
    currentPage,
    generatedUserQuestions,
    saveLoading,
    videoElement,
    stopButtonRef,
    onGenerateQuestionFeedback,
    onCurrentPageChange,
    onStartClick,
  };
}
export default useMockInterview;
