import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';
import React from 'react';

function InterviewInstructions({ questionsLoading, onStartClick }) {
  return (
    <div className="grid grid-cols-1 py-4 px-2">
      <h3 className="my-3 text-lg font-semibold">Interview Instructions</h3>

      <ul className="">
        <li className="mb-2">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-3 mt-2" />
          After starting, you’ll have time to read the question and record when you’re comfortable
        </li>
        <li className=" mb-2">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-3 mt-2" />
          You’re able to take breaks in between questions
        </li>
        <li className=" mb-2">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-3 mt-2" />
          All recordings must be submitted by the specific date
        </li>

        <li className=" mb-2">
          <FontAwesomeIcon icon={faCheckCircle} className="mr-3 mt-2" />
          Please connect with a device having camera and audio supported.
        </li>
      </ul>
      <div className="mt-8">
        {questionsLoading ? (
          <div className="centered spinner-box">
            <Spin />
          </div>
        ) : (
          <div className="flex gap-2">
            <button
              className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600 "
              onClick={() => {
                onStartClick('Technical');
              }}
            >
              Start Technical Interview
            </button>
            <button
              className="btn rounded-lg bg-blue-700 text-white hover:bg-blue-600"
              onClick={() => {
                onStartClick('Behavioral');
              }}
            >
              Start Behavioral Interview
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default InterviewInstructions;
