/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
  create_user_bookmark_contact_action,
  delete_user_contact_details_bookmark_action,
  fetch_user_bookmark_contacts_action,
  update_user_bookmark_contact_action,
} from '../../../_redux/action';

function useSavedContacts(props) {
  const [loading, setLoading] = useState(false);
  const [allContacts, setAllContacts] = useState();
  const [contactForm, setContactForm] = useState(null);
  const [contactModalOpen, setContactModalOpen] = useState(null);
  const [deleteContact, setDeleteContact] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(null);

  // State to manage validation errors
  const [formErrors, setFormErrors] = useState({});

  const dispatch = useDispatch();

  const { bookmarkedContactsData } = useSelector((state) => state.fetch_user_contact_bookmarks_reducer);

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      await dispatch(fetch_user_bookmark_contacts_action());
      setLoading(false);
    };

    callApi();
  }, []);

  useEffect(() => {
    if (bookmarkedContactsData?.data) {
      setAllContacts(bookmarkedContactsData?.data);
    }
  }, [bookmarkedContactsData]);

  // Handles input change and clears validation errors
  const onInputChange = async (e) => {
    setContactForm({ ...contactForm, [e.target.name]: e.target.value });

    // Clear the error for the field being updated
    if (formErrors[e.target.name]) {
      setFormErrors({ ...formErrors, [e.target.name]: null });
    }
  };

  // Validates the form input fields
  const validateForm = () => {
    let errors = {};
    if (!contactForm?.name) errors.name = 'Name is required';
    if (!contactForm?.email) errors.email = 'Email is required';
    if (!contactForm?.contact_number) errors.contact_number = 'Contact number is required';
    if (!contactForm?.company) errors.company = 'Company is required';
    if (!contactForm?.location) errors.location = 'Location is required';
    if (!contactForm?.title) errors.title = 'Title is required';
    if (!contactForm?.bookmark_url) errors.bookmark_url = 'URL is required';
    if (!contactForm?.notes) errors.notes = 'Notes is required';

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // If no errors, return true
  };

  const onContactSave = async () => {
    if (!validateForm()) return; // Exit if the form is invalid

    if (contactForm?._id) {
      await dispatch(update_user_bookmark_contact_action(contactForm._id, contactForm));
    } else {
      await dispatch(create_user_bookmark_contact_action(contactForm));
    }

    await dispatch(fetch_user_bookmark_contacts_action());
    setContactForm({});
    setContactModalOpen((v) => !v);
  };

  const handleContactModalOpen = (item) => {
    if (item) {
      setContactForm(item);
    } else {
      setContactForm({}); // Clear form for adding a new contact
    }
    setFormErrors({}); // Reset errors when opening the modal
    setContactModalOpen((v) => !v);
  };

  const onContactViewClick = async (item) => {
    if (item?.bookmark_url) {
      window.open(item.bookmark_url, null);
    }
  };

  const onDeleteContactConfirm = async () => {
    if (deleteContact?._id) {
      await dispatch(delete_user_contact_details_bookmark_action(deleteContact._id));
      await dispatch(fetch_user_bookmark_contacts_action());
    }
    setDeleteContact(null);
    setDeleteModalOpen((v) => !v);
  };

  const onContactDelete = async (item) => {
    setDeleteContact(item);
    setDeleteModalOpen((v) => !v);
  };
  const onDeleteModelClose = () => {
    setDeleteModalOpen((prev) => !prev);
  };

  return {
    loading,
    allContacts,
    contactForm,
    contactModalOpen,
    deleteContact,
    deleteModalOpen,
    formErrors,

    onDeleteContactConfirm,
    onInputChange,
    onContactSave,
    onContactDelete,
    handleContactModalOpen,
    onContactViewClick,
    onDeleteModelClose,
  };
}
export default useSavedContacts;
