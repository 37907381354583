import React from 'react';
import MainLayout from '../../../_layout/MainLayout';
import useMockInterview from './useMockInterview';
import { Spinner } from 'react-bootstrap';
import InterviewInstructions from './_components/InterviewInstructions';
import InterviewScreen from './_components/InterviewScreen';
import { Spin } from 'antd';

function MockInterview(props) {
  const {
    loading,
    userMockInterview,
    questionsLoading,
    startInterview,
    feedbackLoading,
    questionFeedback,
    currentPage,
    generatedUserQuestions,
    saveLoading,
    videoElement,
    stopButtonRef,
    onGenerateQuestionFeedback,
    onCurrentPageChange,
    onStartClick,
  } = useMockInterview(props);
  return (
    <MainLayout activeMenuItem="home">
      <div className="p-4 sticky max-[540px]:p-2">
        <div className="centered justify-between mb-4 window-container h-[10%]">
          <h2 className="window-title ">
            Mock Interview for {userMockInterview?.job?.title} at {userMockInterview?.job?.company}
          </h2>
        </div>

        {loading ? (
          <div className="centered spinner-box">
            <Spin size="large" />
          </div>
        ) : (
          <div className="h-[90%]">
            {startInterview ? (
              <InterviewScreen
                saveLoading={saveLoading}
                currentPage={currentPage}
                generatedUserQuestions={generatedUserQuestions}
                onCurrentPageChange={onCurrentPageChange}
                videoElement={videoElement}
                stopButtonRef={stopButtonRef}
                feedbackLoading={feedbackLoading}
                questionFeedback={questionFeedback}
                onGenerateQuestionFeedback={onGenerateQuestionFeedback}
              />
            ) : (
              <InterviewInstructions questionsLoading={questionsLoading} onStartClick={onStartClick} />
            )}
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default MockInterview;
