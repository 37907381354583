import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { navigateRoutes } from '../../_config/route';

function useHome() {
  const [isSignedIn, setIsSignedIn] = useState(null);

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('aiclues-user'));

    if (user) {
      setIsSignedIn(true);
    }
  }, []);

  const navigate = useNavigate();

  const onSiginClick = () => {
    navigate(navigateRoutes.signIn);
  };

  const onDashboardClick = () => {
    navigate(navigateRoutes.candidateDashboard);
  };
  return {
    isSignedIn,
    onSiginClick,
    onDashboardClick,
  };
}

export default useHome;
