import React from 'react';
import MuxPlayer from '@mux/mux-player-react/lazy';

function InterviewScreen({
  currentPage,
  generatedUserQuestions,
  onCurrentPageChange,
  saveLoading,
  videoElement,
  stopButtonRef,
  feedbackLoading,
  questionFeedback,
  onGenerateQuestionFeedback,
}) {
  const generatedFeedback = generatedUserQuestions[currentPage]?.feedback || questionFeedback?.feedback;
  return (
    <div>
      <div>
        <h5>
          {currentPage + 1}.{generatedUserQuestions[currentPage]?.question}
        </h5>
        <p>{generatedUserQuestions[currentPage]?.time_to_answer_in_minutes} mins to answer</p>
        {generatedUserQuestions[currentPage]?.video_playback_id ? (
          <div>
            {generatedFeedback && <p>{generatedFeedback}</p>}
            <MuxPlayer
              loading="viewport"
              playbackId={generatedUserQuestions[currentPage]?.video_playback_id}
              streamType="on-demand"
            />
            <button className="btn btn-primary" onClick={() => onCurrentPageChange('add')}>
              Next Question
            </button>
            {feedbackLoading ? (
              <button className="btn btn-secondary">Loading feedback</button>
            ) : (
              <button className="btn btn-primary" onClick={() => onGenerateQuestionFeedback()}>
                Get Feedback
              </button>
            )}
          </div>
        ) : saveLoading ? (
          <div>
            <button className="btn btn-secondary">Saving your response</button>
          </div>
        ) : (
          <div>
            {/* <button className="btn btn-primary m-2" ref={stopButtonRef}>
              Pause Interview
            </button> */}
            <div>
              <video id="player" ref={videoElement} autoPlay muted />
            </div>
            <span>You will be evaluated on: {generatedUserQuestions[currentPage]?.evaluation_parameter}</span>
            <button className="btn btn-primary m-2" ref={stopButtonRef}>
              Save Response
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default InterviewScreen;
